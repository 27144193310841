<template>
  <a-drawer
    v-bind="attrs"
    @close="emit('update:visible', false)"
    class="ant-drawer__have-footer"
    title="选择商家"
    placement="right"
    width="480"
  >
    <div class="my-[5px]">已选择</div>
    <div class="checked-list-box">
      <a-tag closable v-for="item of selectedSuppliers" @close="deleteSelectedSuppler(item)" :key="item.supplier.id">{{
        item.supplier.shortName
      }}</a-tag>
    </div>
    <a-tabs v-model:activeKey="tabs.activeKey" @change="tabs.tabChange">
      <a-tab-pane key="collect" tab="已关注">
        <div>
          <a-input-search
            placeholder="请输入商家简称"
            v-model:value="searchWord"
            @search="getCollectSupplier"
          ></a-input-search>
        </div>
        <e-table
          :data-source="collectedSupplierData"
          :columns="collectedTableColumns"
          :loading="collectedTableLoading"
          row-key="id"
          pagination
          :paging="collectedTablePaging"
          :method="getCollectSupplier"
        >
          <template #checkbox="{ record }">
            <a-checkbox v-model:checked="record.checked" @change="selectedSupplierChange(record)"></a-checkbox>
          </template>
          <template #supplier="{ record }">
            <div class="flex items-center">
              <a-tool-tip :title="record.supplier.shortName">
                <span style="display: inline-block" class="max-w-[110px] truncate">{{
                  record.supplier.shortName
                }}</span>
              </a-tool-tip>
              <span class="text-[#8c8c8c] mx-10px">{{
                record.supplier.manager && record.supplier.manager.cellphone
              }}</span>
              <MessageFilled
                v-if="record.supplier.manager && record.supplier.manager.chatUser"
                @click="webim.open(record.supplier.manager.chatUser)"
                class="cursor-pointer"
                style="color: #ffd100"
              />
            </div>
            <div v-if="businessType === 'W'" class="w-[220px] truncate text-[#8c8c8c]">
              <span v-for="(item, index) of record.supplier.vehicleBrands" :key="index">{{ `${item} / ` }}</span>
            </div>
            <div v-else class="w-[220px] truncate text-[#8c8c8c]">
              <span v-for="(item, index) of record.supplier.parts" :key="index">{{ `${item.message} / ` }}</span>
            </div>
          </template>
          <template #area="{ record }">
            <span>{{ formatArea(record.supplier.area) }}</span>
          </template>
        </e-table>
      </a-tab-pane>
      <a-tab-pane key="all" tab="全国商家">
        <div class="mb-[24px]">
          <a-input-search
            placeholder="请输入商家简称"
            @search="getNationalSupplierMethod"
            v-model:value="supplierName"
          ></a-input-search>
        </div>
        <e-table
          :data-source="nationalSupplierData"
          :columns="nationalTableColumns"
          :loading="nationalTableLoading"
          :method="getNationalSupplierMethod"
          row-key="id"
          pagination
          :paging="nationalTablePaging"
        >
          <template #shortName="{ record }">
            <div class="flex items-center">
              <a-checkbox v-model:checked="record.checked" @change="selectedSupplierChange(record)"></a-checkbox>
              <span class="ml-[10px]">{{ record.shortName }}</span>
            </div>
          </template>
          <template #brand="{ record }">
            <span>{{ record.vehicleBrands }}</span>
          </template>
          <template #part="{ record }">
            <span>{{ record.part }}</span>
          </template>
        </e-table>
      </a-tab-pane>
    </a-tabs>
    <div class="drawer-footer">
      <a-button class="mr-[8px]" @click="emit('update:visible', false)">取消</a-button>
      <a-button type="primary" @click="confirm">确定</a-button>
    </div>
  </a-drawer>
</template>

<script lang="ts" setup>
import { PropType, reactive, ref, computed, useAttrs, watch, nextTick } from 'vue'

import { useTable2, formatArea, useWebim } from '@vue-mfe/utils'
import { MessageFilled } from '@ant-design/icons-vue'

import {
  message,
  Checkbox as ACheckbox,
  Tag as ATag,
  Drawer as ADrawer,
  Tabs as ATabs,
  TabPane as ATabPane,
  Tooltip as AToolTip,
  InputSearch as AInputSearch
} from 'ant-design-vue'

interface SupplierItem {
  supplier: {
    id: number | string
    shortName: string
  }
}
// @ts-ignore
const props = defineProps(
  {
    suppliers: {
      type: Array as PropType<SupplierItem[]>,
      defalut: () => []
    },
    excludedSupplierIds: Array as PropType<string[]>,
    businessType: {
      type: String as PropType<string>,
      default: ''
    },
    vehicleBrand: {
      type: String as PropType<string>,
      default: ''
    },
    activeKey: {
      type: String as PropType<string>,
      default: 'collect'
    }
  }
)
const attrs = useAttrs()
const webim = useWebim()

const emit = defineEmits(['confirm', 'update:visible'])

let selectedSuppliers = ref<SupplierItem[]>([])

const deleteSelectedSuppler = (item: SupplierItem) => {
  const index = selectedSuppliers.value.findIndex((inner: any) => inner.supplier.id === item.supplier.id)

  selectedSuppliers.value.splice(index, 1)
  const collectRow = collectedSupplierData.value?.find((inner: any) => inner.supplier.id === item.supplier.id)
  if (collectRow) collectRow.checked = false
  const allRow = nationalSupplierData.value?.find((inner: any) => inner.id === item.supplier.id)
  if (allRow) allRow.checked = false
}

const selectedSupplierChange = (record: any) => {
  const key = tabs.activeKey
  if (record.checked) {
    if (selectedSuppliers.value.length < 3) {
      const item = {
        supplier: {
          id: key === 'collect' ? record.supplier.id : record.id,
          shortName: key === 'collect' ? record.supplier.shortName : record.shortName
        }
      }
      selectedSuppliers.value.push(item)
    } else {
      message.error('只能选择三家供应商')
      record.checked = false
    }
  } else {
    const index = selectedSuppliers.value.findIndex(
      (item: any) => item.supplier.id === (key === 'collect' ? record.supplier.id : record.id)
    )
    selectedSuppliers.value.splice(index, 1)
  }
}

const tabs = reactive(
  {
    activeKey: 'collect',
    tabChange: (key: string) => {
      supplierName.value = ''
      searchWord.value = ''
      if (key === 'collect') {
        getCollectSupplier()
      } else {
        getNationalSupplierMethod()
      }
    }
  }
)

const searchWord = ref('')
// 获取已关注
const {
  data: collectedSupplierData,
  columns: collectedTableColumns,
  loading: collectedTableLoading,
  paging: collectedTablePaging,
  run: getCollectSupplier
} = useTable2(
  {
    ajax: {
      get: {
        action: 'GET /client/supplier/collect',
        params: () => [
          {
            excludedSupplierIds: props.excludedSupplierIds,
            businessType: props.businessType,
            quickSearch: searchWord.value
          }
        ],
        convert: {
          client (data: any) {
            return data.map(
              (item: any) => {
                const index = selectedSuppliers.value.findIndex((inner) => inner.supplier.id === item.supplier.id)
                if (~index) {
                  item.checked = true
                } else {
                  item.checked = false
                }
                return item
              }
            )
          }
        },
        lazy: true
      }
    },
    columns: [
      {
        title: '',
        width: '50',
        slots: { customRender: 'checkbox' }
      },
      {
        title: '商家',
        dataIndex: 'supplier',
        slots: { customRender: 'supplier' }
      },
      {
        title: '地区',
        slots: { customRender: 'area' }
      }
    ]
  }
)

const supplierName = ref('')
// 获取全国供应商
const {
  data: nationalSupplierData,
  loading: nationalTableLoading,
  paging: nationalTablePaging,
  actions: nationalTableAction,
  run: getNationalSupplier
} = useTable2(
  {
    ajax: {
      get: {
        action: 'GET /client/common/supplier',
        params: () => [
          {
            vehicleBrand: props.vehicleBrand,
            businessType: props.businessType,
            excludedSupplierIds: props.excludedSupplierIds,
            name: supplierName.value
          }
        ],
        convert: {
          client (data: any) {
            return data.map(
              (item: any) => {
                const index = selectedSuppliers.value.findIndex((inner) => inner.supplier.id === item.id)
                if (~index) {
                  item.checked = true
                } else {
                  item.checked = false
                }
                return item
              }
            )
          }
        },
        lazy: true
      }
    },
    columns: []
  }
)
const getNationalSupplierMethod = () => {
  nationalTableAction.setPaging(
    {
      pageSize: 10
    }
  )
  getNationalSupplier()
}
const nationalTableColumns = computed(
  () => {
    if (props.businessType === 'W') {
      return [
        {
          title: '商家',
          align: 'center',
          slots: { customRender: 'shortName' }
        },
        {
          title: '经营类型',
          width: '200',
          ellipsis: true,
          slots: { customRender: 'brand' }
        },
        {
          title: '地区',
          dataIndex: 'area'
        }
      ]
    } else if (props.businessType === 'CU') {
      return [
        {
          title: '商家',
          align: 'center',
          slots: { customRender: 'shortName' }
        },
        {
          title: '保养易损件',
          width: '200',
          ellipsis: true,
          slots: { customRender: 'part' }
        },
        {
          title: '地区',
          dataIndex: 'area'
        }
      ]
    } else return []
  }
)

const confirm = () => {
  emit('update:visible', false)
  emit('confirm', selectedSuppliers.value)
  selectedSuppliers.value = []
}

function init () {
  if (props.suppliers) selectedSuppliers.value = [...props.suppliers]
  if (props.activeKey === 'all') {
    tabs.activeKey = 'all'
    if (!nationalTablePaging.value.pageSize) {
      nationalTableAction.setPaging(
        {
          pageSize: 10
        }
      )
    }
    getNationalSupplier()
  } else {
    getCollectSupplier()
  }
}
watch(
  () => attrs.visible,
  (visible) => {
    if (visible) {
      nextTick(init)
    }
  }
)
</script>

<style scoped></style>
